import React from 'react';
import { Alert, Button, Container, Form, FormControl } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import {
   getMyAuthorizedAccounts,
   loginRequest,
   logoutRequest,
} from '../actions/auth';
import AlertModal from '../components/AlertModal';
import FormInput from '../components/FormInput';
import LoginComponent from '../components/LoginComponent';

class Login extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         email: '',
         password: '',
         loading: false,
         success: false,
         error: false,
      };
      this.AlertModal = React.createRef();
   }

   componentDidMount() {}

   render() {
      const { t } = this.props;
      return (
         <Container style={{ maxWidth: 500, marginTop: 100, borderRadius: 5 }}>
            <AlertModal onRef={(ref) => (this.AlertModal = ref)} />
            <LoginComponent
               onLoginSuccess={(response) => {
                  getMyAuthorizedAccounts(
                     (authorized_accounts) => {
                        console.log(authorized_accounts.length);
                        const role = response.account.role;
                        if (
                           role == 'private' &&
                           authorized_accounts.length == 0
                        ) {
                           logoutRequest(
                              () => {},
                              () => {},
                           );
                           this.AlertModal.show(
                              'Private Account',
                              t('errors.pleaseUseMobileApp'),
                              () => {
                                 this.props.history.push('login');
                              },
                           );
                           return;
                        }

                        localStorage.setItem(
                           'AuthorizedAccounts',
                           JSON.stringify(authorized_accounts),
                        );
                        this.props.history.push(`/home`);
                        window.location.reload();
                     },
                     (error) => {},
                  );
               }}
            />
         </Container>
      );
   }
}

export default withTranslation()(Login);
