import PropTypes from 'prop-types';
import React from 'react';
import { Alert, Button, Container, Form, FormControl } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import { getMyAuthorizedAccounts, loginRequest } from '../actions/auth';
import FormInput from '../components/FormInput';

class LoginComponent extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         email: props.email,
         password: '',
         loading: false,
         success: false,
         error: false,
      };
   }

   componentDidMount() {}

   handleLogin = (event) => {
      this.setState({ loading: true, success: false, error: false }, () => {
         const loginObject = {
            email: this.state.email,
            password: this.state.password,
            platform: 'React',
            app_version: '0',
         };
         loginRequest(loginObject, this.loginSuccess, this.loginError);
      });
   };

   loginSuccess = (response) => {
      this.setState({ loading: false }, () => {
         if (this.props.onLoginSuccess) {
            this.props.onLoginSuccess(response);
         }
      });
   };

   loginError = (error) => {
      const errorMessage = this.props.t(
         `errors.${error.response.data.language_variable}`,
      );
      this.setState({ loading: false, success: false, error: errorMessage });
   };

   handleKeypress = (e) => {
      //it triggers by pressing the enter key
      if (e.key === 'Enter') {
         if (this.state.email && this.state.password) {
            this.handleLogin();
         }
      }
   };

   render() {
      const { t } = this.props;
      return (
         <>
            <FormInput
               id='email'
               name='email'
               label={t('Login.email')}
               type='email'
               value={this.state.email}
               onChange={(text) => {
                  this.setState({ email: text.target.value });
               }}
               onKeyPress={this.handleKeypress}
            />

            <FormInput
               id='password'
               name='password'
               label={t('Login.password')}
               role='password'
               type='password'
               value={this.state.password}
               onChange={(text) => {
                  this.setState({ password: text.target.value });
               }}
               onKeyPress={this.handleKeypress}
            />

            <Button
               id='btnLogin'
               variant='primary'
               onClick={() => this.handleLogin()}>
               {t('Login.login')}
            </Button>
            <div style={{ height: 15 }} />
            {this.state.loading && (
               <Alert key={1} variant={'primary'}>
                  {t('global.loading')}
               </Alert>
            )}

            {this.state.error && (
               <Alert id={'AlertError'} key={1} variant={'danger'}>
                  {this.state.error}
               </Alert>
            )}

            <a
               href={`${process.env.REACT_APP_WEB_APP_LINK}ForgotPassword/${this.state.email}`}>
               {t(`Login.forgotPassword`)}
            </a>
            <br></br>
            <a href={`${process.env.REACT_APP_WEB_APP_LINK}register`}>
               {t(`Login.register`)}
            </a>
         </>
      );
   }
}

LoginComponent.defaultProps = {
   email: '',
};
FormInput.propTypes = {
   email: PropTypes.string,
};

export default withTranslation()(LoginComponent);
