import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

class AlertModel extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         title: '',
         text: '',
         show: false,
      };

      this.callback = () => {};
   }

   componentDidMount() {
      this.props.onRef(this);
   }

   show = (title, text, callback) => {
      this.setState({ show: true, title: title, text: text });
      this.callback = callback;
   };

   render() {
      const { t } = this.props;
      return (
         <Modal
            id='modal_alert'
            show={this.state.show}
            onHide={() => this.setState({ show: false })}>
            <Modal.Header closeButton>
               <Modal.Title>{this.state.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{this.state.text}</Modal.Body>
            <Modal.Footer>
               <Button
                  id='button_ok'
                  variant='primary'
                  onClick={() => {
                     this.setState({ show: false });
                     this.callback();
                  }}>
                  {t(`global.ok`)}
               </Button>
            </Modal.Footer>
         </Modal>
      );
   }
}

export default withTranslation()(AlertModel);
