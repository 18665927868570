import React from 'react';
import { Col, Container, FormControl, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import { getMyMoorings } from '../actions/moorings';
import TableIcon from '../assets/icons/table.png';
import AddNewButton from '../components/AddNewButton';
import FormInput from '../components/FormInput';
import MooringItem from '../components/MooringItem';
import globalStyles from '../stylesheets/globalStyles';
import { deepSearchString } from '../utils/utilities';

class MaintainMoorings extends React.Component {
   constructor(props) {
      super(props);
      this.myRef = React.createRef(); // Create a ref object
      this.state = {
         loading: false,
         success: false,
         error: false,
         searchText: '',
         moorings: [],
         filteredMoorings: [],
      };
   }

   componentDidMount() {
      this.props.onRef && this.props.onRef(this);

      getMyMoorings(this.getMyMooringsSuccess, this.getMyMooringsError);

      const scrollTop = sessionStorage.getItem(
         'maintain_moorings_scroll_height',
      );

      setTimeout(
         function () {
            //Start the timer
            if (this.myRef.current) {
               this.myRef.current.scrollTo(0, scrollTop);
            }
         }.bind(this),
         400,
      );
   }

   componentWillUnmount() {
      if (this.myRef.current) {
         sessionStorage.setItem(
            'maintain_moorings_scroll_height',
            this.myRef.current.scrollTop,
         );
      }
   }

   loadMoorings = () => {
      console.log('Load moorings');
      getMyMoorings(this.getMyMooringsSuccess, this.getMyMooringsError);
   };
   getMyMooringsSuccess = (moorings) => {
      moorings = moorings.map((mooring) => {
         mooring.contact_string = JSON.stringify(mooring.assigned_contact);
         return mooring;
      });
      this.setState({ moorings: moorings, filteredMoorings: moorings });
   };

   getMyMooringsError = (response) => {
      this.setState({
         loading: false,
         success: false,
         error: 'something went wrong',
      });
   };

   onClickTableView = () => {
      this.props.history.push({
         pathname: `/mooringtable/`,
      });
   };

   onChangeSearchText = (event) => {
      const searchText = event.target.value;
      var filteredMoorings = [];
      if (searchText == undefined || searchText == '') {
         filteredMoorings = this.state.moorings;
      } else {
         filteredMoorings = deepSearchString(this.state.moorings, searchText);
      }
      this.setState({
         searchText: searchText,
         filteredMoorings: filteredMoorings,
      });
   };

   render() {
      const authenticated = localStorage.getItem('AuthToken');
      const moorings = this.state.filteredMoorings;
      const { t } = this.props;

      return (
         <div
            id='maintain_moorings_container'
            ref={this.myRef}
            style={{
               ...globalStyles.leftHalfScreenContainer,
               boxShadow: 'none',
            }}>
            <div style={{ width: '90%' }}>
               <Row>
                  <Col xs={11} md={11}>
                     <FormInput
                        id={'input_search'}
                        label=''
                        value={this.state.searchText}
                        placeholder={t('global.search')}
                        onChange={(event) => {
                           this.onChangeSearchText(event);
                        }}
                        last
                     />
                  </Col>
                  <Col xs={1} md={1}>
                     <img
                        style={{ width: 36, marginTop: 2, float: 'left' }}
                        onClick={this.onClickTableView}
                        src={TableIcon}
                        alt='tableicon'
                     />
                  </Col>
               </Row>
            </div>

            <div>
               {this.state.filteredMoorings.map((mooring) => (
                  <MooringItem
                     key={mooring.mooring_id}
                     mooring={mooring}
                     history={this.props.history}
                     editClicked={() => this.props.editClicked(mooring)}
                     infoClicked={() => this.props.infoClicked(mooring)}
                     setMapPosition={this.props.setMapPosition}
                     pointOutMooring={this.props.pointOutMooring}
                  />
               ))}

               <AddNewButton
                  center
                  label={t('MaintainMoorings.addNewMooring')}
                  onClick={this.props.onAddMooring}
               />
            </div>
         </div>
      );
   }
}

export default withTranslation()(MaintainMoorings);
