import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

class YesNoModal extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         title: '',
         text: '',
         show: false,
      };

      this.YesCallback = () => {};
      this.NoCallback = () => {};
   }

   componentDidMount() {
      this.props.onRef(this);
   }

   show = (title, text, YesCallback, NoCallback) => {
      this.setState({ show: true, title: title, text: text });
      this.YesCallback = YesCallback;
      this.NoCallback = NoCallback;
   };

   render() {
      const { t } = this.props;
      return (
         <Modal
            id='modal_yes_no'
            show={this.state.show}
            onHide={() => this.setState({ show: false })}>
            <Modal.Header closeButton>
               <Modal.Title>{this.state.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{this.state.text}</Modal.Body>
            <Modal.Footer>
               <Button
                  id='button_no'
                  variant='secondary'
                  onClick={() => {
                     this.setState({ show: false });
                     this.NoCallback();
                  }}>
                  {t(`global.no`)}
               </Button>
               <Button
                  id='button_yes'
                  variant='primary'
                  onClick={() => {
                     this.setState({ show: false });
                     this.YesCallback();
                  }}>
                  {t(`global.yes`)}
               </Button>
            </Modal.Footer>
         </Modal>
      );
   }
}

export default withTranslation()(YesNoModal);
