import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import {
   createAssignedContact,
   deleteAssignedContact,
   updateAssignedContact,
} from '../actions/assigned_contacts';
import { getBoatsByContactId } from '../actions/boats';
import { getMyContacts } from '../actions/contacts';
import ContactIcon from '../assets/icons/contact.png';
import TableIcon from '../assets/icons/table.png';
import globalStyles from '../stylesheets/globalStyles';
import type {
   AssignedContact,
   Contact,
   WaitingContact,
} from '../types/Contact';
import { validateEditAssignedContactModal } from '../utils/validators';
import ContactListModal from './ContactListModal';
import DocumentMaintainArea from './DocumentMaintainArea';
import FormCheckbox from './FormCheckbox';
import FormInput from './FormInput';
import WaitingContactListModal from './WaitingContactListModal';

const i18nPrefix = 'MaintainAssignedContacts';

type Props = {
   mooring_id: number;
   onRef: React.MutableRefObject<{ show: (assigned_contact?) => void }>;
   onClose: () => void;
};

function EditAssignedContactModal(props: Props) {
   const [showModal, setShowModal] = useState(false);
   const [showContactListModal, setShowContactListModal] = useState(false);
   const [showWaitingContactListModal, setShowWaitingContactListModal] =
      useState(false);
   const [edit, setEdit] = useState(false);
   const [assigned_contact, setAssigned_contact] = useState<AssignedContact>({
      id: null,
   });
   const [boats, setBoats] = useState([]);
   const [contacts, setContacts] = useState([]);

   const { t } = useTranslation();
   const EditContactModal = useRef<{ show: (contact) => void }>(null);

   useEffect(
      () => {
         getMyContacts(
            (contacts) => {
               setContacts(contacts);
            },
            () => {},
         );

         if (props.onRef) {
            props.onRef.current = { show };
         }
         return () => {
            if (props.onRef) {
               props.onRef.current = undefined;
            }
         };
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
   );

   const show = (assigned_contact: AssignedContact) => {
      console.log('show', assigned_contact);
      setAssigned_contact(assigned_contact);
      setEdit(!!assigned_contact?.id);
      setShowModal(true);
   };

   const handleClose = () => {
      props.onClose && props.onClose();
      setShowModal(false);
   };

   /* const onClickTableView = () => {
      console.log('location.pathname', location.pathname);
      history.push('/ContactTable/', {
         selecting: true,
         lastScreen: location.pathname,
         mooring_id: props.mooring_id,
      });
   };*/

   /*const onClickWaitingContacts = () => {
      history.push('/WaitingContactsTable/', {
         selecting: true,
         lastScreen: location.pathname,
         mooring_id: props.mooring_id,
      });
   };*/

   const createButtonClicked = () => {
      const formErrors = validateEditAssignedContactModal(assigned_contact);
      const noErrors = Object.values(formErrors).every((x) => x == false);
      if (!noErrors) {
         alert(t('errors.someValuesAreNotCorrect'));
         return;
      }
      createAssignedContact(
         { ...assigned_contact, mooring_id: props.mooring_id },
         () => {
            handleClose();
         },
         () => {},
      );
   };

   const updateButtonClicked = () => {
      updateAssignedContact(
         assigned_contact,
         () => {
            handleClose();
         },
         () => {},
      );
   };

   const deleteButtonClicked = () => {
      deleteAssignedContact(
         assigned_contact.id,
         () => {
            handleClose();
         },
         () => {},
      );
   };

   /*const loadBoats = (contact_id) => {
      if (!contact_id) {
         setBoats([]);
         setAssigned_contact((prev) => ({
            ...prev,
            boat_id: null,
         }));
      }

      getBoatsByContactId(
         contact_id,
         (boats_reponse) => {
            console.log('boats', boats_reponse);
            setBoats(boats_reponse);
            if (boats_reponse[0] && !edit) {
               //in case of new entry, automatically select first boat
               setAssigned_contact((prev) => ({
                  ...prev,
                  boat_id: boats_reponse[0].id,
               }));
            }
         },
         () => {},
      );
   };*/

   // it runs automatically if assigned_contact?.contact_id changes or edit changes
   useEffect(() => {
      if (assigned_contact?.contact_id) {
         getBoatsByContactId(
            assigned_contact.contact_id,
            (boats_reponse) => {
               // console.log('boats', boats_reponse);
               setBoats(boats_reponse);
               if (boats_reponse.length > 0 && !edit) {
                  //in case of new entry, automatically select first boat
                  setAssigned_contact((prev) => ({
                     ...prev,
                     boat_id: boats_reponse[0].id,
                  }));
               }
            },
            () => {},
         );
      } else {
         setBoats([]);
         setAssigned_contact((prev) => ({
            ...prev,
            boat_id: null,
         }));
      }
   }, [assigned_contact?.contact_id, edit]);

   console.log('assigned_contact', assigned_contact);
   const formErrors = validateEditAssignedContactModal(assigned_contact);
   return (
      <>
         <ContactListModal
            showModal={showContactListModal}
            hideModal={() => setShowContactListModal(false)}
            onSelectContact={(contact: Contact) => {
               console.log('selected contact: ', contact);
               setAssigned_contact((prev) => ({
                  ...prev,
                  contact_id: contact.id,
               }));
               setShowContactListModal(false);
            }}
         />
         <WaitingContactListModal
            showModal={showWaitingContactListModal}
            hideModal={() => setShowWaitingContactListModal(false)}
            onSelectContact={(contact: WaitingContact) => {
               console.log('selected contact: ', contact);
               setAssigned_contact((prev) => ({
                  ...prev,
                  contact_id: contact.contact_id,
               }));
               setShowWaitingContactListModal(false);
            }}
         />
         <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
               <Modal.Title>
                  {edit
                     ? t(`${i18nPrefix}.editRenter`)
                     : t(`${i18nPrefix}.addRenter`)}
               </Modal.Title>
            </Modal.Header>

            <Modal.Body>
               <Row>
                  <Col md={9}>
                     {/*  id prop required for the next FormInput */}
                     <FormInput
                        as='select'
                        label={t(`${i18nPrefix}.contact`)}
                        type='text'
                        value={assigned_contact?.contact_id}
                        onChange={(e) => {
                           console.log('e.target.value', e.target.value);
                           setAssigned_contact((prev) => ({
                              ...prev,
                              contact_id: e.target.value,
                           }));
                           // loadBoats(e.target.value);
                        }}>
                        <option key={0}>{t(`global.pleaseSelect`)}</option>
                        {contacts
                           .sort((a, b) => (a.lastname > b.lastname ? 1 : -1))
                           .map((contact) => (
                              <option
                                 key={contact.id}
                                 value={
                                    contact.id
                                 }>{`${contact.lastname} ${contact.firstname} (${contact.street} ${contact.zip}, ${contact.city})`}</option>
                           ))}
                     </FormInput>
                  </Col>

                  <Col md={3}>
                     <img
                        style={{
                           width: 36,
                           marginTop: 20,
                           float: 'right',
                        }}
                        // onClick={onClickTableView}
                        onClick={() => {
                           setShowContactListModal(true);
                        }}
                        src={TableIcon}
                        alt='tableicon'
                     />
                     {assigned_contact?.contact && (
                        <img
                           style={{
                              width: 36,
                              marginTop: 20,
                              float: 'right',
                           }}
                           onClick={() => {
                              EditContactModal.current?.show(
                                 assigned_contact?.contact,
                              );
                           }}
                           src={ContactIcon}
                           alt='tableicon'
                        />
                     )}
                  </Col>
               </Row>
               <button
                  style={globalStyles.normalButton}
                  onClick={() => {
                     setShowWaitingContactListModal(true);
                  }}>
                  {t(`${i18nPrefix}.choseFromWaitingContacts`)}
               </button>

               <FormInput
                  as='select'
                  label={t(`${i18nPrefix}.boat`)}
                  type='text'
                  value={assigned_contact?.boat_id}
                  onChange={(e) => {
                     const selected_boat_id =
                        e.target.value == 'none' ? null : e.target.value; // Select does not take "null" as key
                     setAssigned_contact({
                        ...assigned_contact,
                        boat_id: selected_boat_id,
                     });
                  }}>
                  <option key={0} value={'none'}>
                     {'-'}
                  </option>

                  {boats.map((boat) => (
                     <option
                        key={boat.id}
                        value={
                           boat.id
                        }>{`${boat.registration_number}, ${boat.name} (${boat.length} ${boat.beam}, ${boat.draft})`}</option>
                  ))}
               </FormInput>

               <div />

               <FormInput
                  label={t(`${i18nPrefix}.from`)}
                  type='date'
                  value={assigned_contact?.from_date}
                  error={formErrors.from_date}
                  onChange={(e) => {
                     setAssigned_contact({
                        ...assigned_contact,
                        from_date: e.target.value,
                     });
                  }}
               />

               <FormInput
                  label={t(`${i18nPrefix}.to`)}
                  type='date'
                  value={assigned_contact?.to_date}
                  error={formErrors.to_date}
                  onChange={(e) => {
                     setAssigned_contact({
                        ...assigned_contact,
                        to_date: e.target.value,
                     });
                  }}
               />

               <FormInput
                  as='textarea'
                  label={t(`${i18nPrefix}.notes`)}
                  type='textarea'
                  rows={2}
                  value={assigned_contact?.notes}
                  onChange={(e) => {
                     setAssigned_contact({
                        ...assigned_contact,
                        notes: e.target.value,
                     });
                  }}
               />

               <FormCheckbox
                  label={t(`${i18nPrefix}.correspondence`)}
                  checked={assigned_contact?.correspondence}
                  onChange={(e) => {
                     setAssigned_contact({
                        ...assigned_contact,
                        correspondence: e.target.checked,
                     });
                  }}
               />

               <FormCheckbox
                  label={t(`${i18nPrefix}.exemption`)}
                  checked={assigned_contact?.exemption}
                  onChange={(e) => {
                     setAssigned_contact({
                        ...assigned_contact,
                        exemption: e.target.checked,
                     });
                  }}
               />

               <FormCheckbox
                  label={t(`${i18nPrefix}.provisional`)}
                  checked={assigned_contact?.provisional}
                  onChange={(e) => {
                     setAssigned_contact({
                        ...assigned_contact,
                        provisional: e.target.checked,
                     });
                  }}
               />

               {assigned_contact?.id && (
                  <Row>
                     <DocumentMaintainArea
                        id_params={{
                           assigned_contact_id: assigned_contact?.id,
                        }}
                     />
                  </Row>
               )}
            </Modal.Body>
            <Modal.Footer>
               {!edit ? (
                  <Button
                     style={globalStyles.saveButton}
                     onClick={createButtonClicked}>
                     {t(`global.create`)}
                  </Button>
               ) : (
                  <>
                     <Button
                        style={globalStyles.deleteButton}
                        onClick={deleteButtonClicked}>
                        {t(`global.delete`)}
                     </Button>

                     <Button
                        style={globalStyles.saveButton}
                        onClick={updateButtonClicked}>
                        {t(`global.save`)}
                     </Button>
                  </>
               )}
            </Modal.Footer>
         </Modal>
      </>
   );
}

export default EditAssignedContactModal;
